import { Box, Divider, Typography } from '@mui/material';
import { OATypography } from '../../../../components/widgets';
import { theme } from '../../../../config/theme';
import { getFormattedDate } from '../../../../helper';
import OrderInvoice from '../../OrderInvoice';
import { calculateTotalExcludingCtMarkup } from '../PaymentSummary';
import {
  getCleartripRefundTable,
  getCleartripTable,
  roundTripSupplierTableData
} from '../PaymentSummaryTables/supplierTables';
import { useState } from 'react';
import { CustomAccordion, CustomTableAccordion } from '../FlightTabHelpers/AccordianTable';
import { ReactComponent as DoubleArrowIcon } from '../../../../assets/icons/DoubleArrows.svg';
import { ReactComponent as SourceDestIcon } from '../../../../assets/icons/source-DestArrow.svg';
import OAChip from '../../../../components/Base/OAChip';
import { SMBCount, getSMBChargesTable } from '../PaymentSummaryTables/customerTables';
interface ITravelDetails {
  data: any;
  isLoading: boolean;
}

export default function Supplier(props: ITravelDetails) {
  // Using an object to maintain the state of multiple accordions
  const [accordionStates, setAccordionStates] = useState({
    onwardExpand: true,
    returnExpand: true,
    onwardTax: true,
    returnTax: true,
    smbOnwardExpand: true
  });

  // Generalized handler function
  const handleAccordionChange = (accordion: string, isExpanded: boolean) => {
    setAccordionStates(prevStates => ({
      ...prevStates,
      [accordion]: isExpanded
    }));
  };

  let onward = props?.data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );
  let returnData = props?.data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );

  const orderAmount = props?.data?.orderAmount;
  const currency = orderAmount?.currency;
  const onwardBreakup = orderAmount?.flightBasedBreakUp?.find(
    (onwardBreakup: any) => onwardBreakup?.flightId === onward?.id
  );

  const returnBreakup = orderAmount?.flightBasedBreakUp?.find(
    (returnBreakup: any) => returnBreakup?.flightId === returnData?.id
  );

  const onwardTaxAmount = onwardBreakup?.taxesAndFees
    ? `${currency} ${calculateTotalExcludingCtMarkup(onwardBreakup?.taxesAndFees)}`
    : 0;

  const returnTaxAmount = returnBreakup?.taxesAndFees
    ? `${currency} ${calculateTotalExcludingCtMarkup(returnBreakup?.taxesAndFees)}`
    : 0;

  const cleartripTableData = getCleartripTable(props, onward);
  const cleartripRefundTableData = getCleartripRefundTable(props);
  const sourceTable = roundTripSupplierTableData(props);
  const cleartripRoundtripTableData = roundTripSupplierTableData(props);

  const onwardCount = SMBCount(onward);
  const returnCount = SMBCount(returnData);
  const seatCount = onwardCount?.seatCount + returnCount?.seatCount;
  const mealCount = onwardCount?.mealCount + returnCount?.mealCount;
  const baggageCount = onwardCount?.baggageCount + returnCount?.baggageCount;
  let smbCount = { seatCount, mealCount, baggageCount };
  const SMBChargesData: any = getSMBChargesTable(props, smbCount);

  const onwardTripTable = cleartripRoundtripTableData?.splice(1, 1);
  const returnTripTable = cleartripRoundtripTableData?.splice(2, 2);
  let onwardTaxTable;
  let returnTaxTable;
  const seatCharges = orderAmount?.seatCharges;
  const mealCharges = orderAmount?.mealCharges;
  const baggageCharges = orderAmount?.baggageCharges;
  const addOnCharges = parseFloat(seatCharges + mealCharges + baggageCharges);

  let onwardTaxesAndFees = onwardBreakup?.taxesAndFees.filter(
    (item: { display: string }) => item.display !== 'Ct Markup'
  );
  if (onwardBreakup && onwardBreakup !== 'undefined') {
    onwardTaxTable = onwardTaxesAndFees?.map((fee: any, index: any) => ({
      id: index + 1,
      label: fee.display,
      value: `INR ${fee.amount}`,
      header: false,
      roundTrip: true
    }));
  }

  const returnTaxesAndFees = returnBreakup?.taxesAndFees.filter(
    (item: { display: string }) => item?.display !== 'Ct Markup'
  );

  if (returnBreakup && returnBreakup !== 'undefined') {
    returnTaxTable = returnTaxesAndFees?.map((fee: any, index: any) => ({
      id: index + 1,
      label: fee.display,
      value: `INR ${fee.amount}`,
      header: false,
      roundTrip: true
    }));
  }

  const newSupplierTable =
    addOnCharges > 0 ? cleartripTableData.slice(0, -2) : cleartripTableData.slice(0, 3);
  const finalSource = cleartripTableData ?? sourceTable;
  const TripType = () => {
    return (
      <>
        {props?.data?.flights?.length <= 1 ? (
          <Box display='flex' gap='8px' alignItems='center'>
            <OATypography variant='body1'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <SourceDestIcon height={16} />
            <OATypography variant='body1'>{onwardArrival?.arrival?.airport?.city}</OATypography>
          </Box>
        ) : (
          <Box display='flex' gap='8px' alignItems='center'>
            <OATypography variant='body1'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <DoubleArrowIcon height={16} />
            <OATypography variant='body1'>{returnArrival?.departure?.airport?.city}</OATypography>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box>
      <OATypography variant='subtitle2' sx={{ padding: '16px 24px' }}>
        Payment details
      </OATypography>
      <Divider />
      <div className='card-body py-0'>
        {(props?.data?.flights?.length <= 1 ||
          props?.data?.orderAmount?.flightBasedBreakUp?.length < 1) && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 8px'
              }}>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: '12px', lineHeight: '16px', color: 'grey.400' }}>
                PRODUCT
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: 'grey.400'
                }}>
                AMOUNT
              </Typography>
            </Box>
            <Divider />
            <CustomTableAccordion
              CustomLabel={TripType}
              tableData={newSupplierTable}
              value={newSupplierTable[0]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('onwardExpand', isExpanded)
              }
              expand={accordionStates?.onwardExpand}
              flightType={'ONWARD'}
            />
          </>
        )}
        {props?.data?.orderAmount?.flightBasedBreakUp?.length > 1 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 8px'
              }}>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: '12px', lineHeight: '16px', color: 'grey.400' }}>
                PRODUCT
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: '12px', lineHeight: '16px', color: 'grey.400' }}>
                AMOUNT
              </Typography>
            </Box>
            <Divider />
            <CustomAccordion
              label={cleartripRoundtripTableData[0]?.label}
              tableObj={onwardTripTable}
              value={cleartripRoundtripTableData[0]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('onwardExpand', isExpanded)
              }
              expand={accordionStates?.onwardExpand}
              flightType={'ONWARD'}
              tax={onwardTaxTable?.length > 0 ? true : false}
              taxLabel={`Taxes & fees ${props?.data?.flights?.length > 1 ? '(ONWARD)' : ''}`}
              taxValue={onwardTaxAmount}
              taxExpand={accordionStates?.onwardTax}
              taxCallback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('onwardTax', isExpanded)
              }
              filteredData={onwardTaxTable}
            />
            {!accordionStates?.onwardExpand && <Divider />}
            <CustomAccordion
              label={cleartripRoundtripTableData?.[1]?.label}
              tableObj={[returnTripTable?.[0]]}
              value={cleartripRoundtripTableData?.[1]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('returnExpand', isExpanded)
              }
              expand={accordionStates?.returnExpand}
              flightType={'RETURN'}
              tax={returnTaxTable?.length > 0 ? true : false}
              taxLabel={`Taxes & fees ${props?.data?.flights?.length > 1 ? '(RETURN)' : ''}`}
              taxValue={returnTaxAmount}
              taxExpand={accordionStates?.returnTax}
              taxCallback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('returnTax', isExpanded)
              }
              filteredData={returnTaxTable}
            />
            {!accordionStates?.returnExpand && <Divider />}
          </>
        )}
        {addOnCharges > 0 && (
          <>
            <CustomTableAccordion
              label={SMBChargesData?.[0]?.label}
              tableData={SMBChargesData}
              value={SMBChargesData?.[0]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('smbOnwardExpand', isExpanded)
              }
              expand={accordionStates?.smbOnwardExpand}
            />
            {!accordionStates?.smbOnwardExpand && <Divider />}
          </>
        )}
      </div>
      {finalSource?.map((obj: any, index: number) => (
        <>
          {obj?.label?.includes('Total') && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 34px'
                }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <Typography variant='subtitle2'>{obj?.label}</Typography>
                </Box>
                <OATypography variant='subtitle2'>{obj?.value ?? 'N/A'}</OATypography>
              </Box>
              <Divider />
            </>
          )}
          {index >= finalSource?.length - 2 && !obj?.label?.includes('Total') && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 34px'
                }}>
                <Typography variant='body1'>{obj?.label}</Typography>
                <OATypography variant='body1'>{obj?.value ?? 'N/A'}</OATypography>
              </Box>
              <Divider />
            </>
          )}
        </>
      ))}
      {props?.data?.orderAmount?.refundInfo?.length > 0 && (
        <>
          <Box sx={{ padding: '16px' }}>
            <OATypography variant='subtitle2'>Refund details</OATypography>
            <Box display='flex' alignItems='center' marginLeft='16px' gap='71px' marginTop='24px'>
              <Box>
                <OATypography
                  variant='subtitle2'
                  sx={{
                    fontSize: '10px',
                    lineHeight: '16px',
                    color: theme.palette.grey[400],
                    textTransform: 'uppercase'
                  }}>
                  Refund date
                </OATypography>
                <OATypography variant='body2'>
                  {props?.data?.orderAmount?.refundInfo?.[0]?.refundDate
                    ? getFormattedDate(
                        props?.data?.orderAmount?.refundInfo?.[0]?.refundDate,
                        'DD MMM, YYYY'
                      )
                    : 'N/A'}
                </OATypography>
              </Box>
              <Box>
                <OATypography
                  variant='subtitle2'
                  sx={{
                    fontSize: '10px',
                    lineHeight: '16px',
                    color: theme.palette.grey[400],
                    textTransform: 'uppercase'
                  }}>
                  Refund Id
                </OATypography>
                <OATypography variant='body2'>
                  {props?.data?.orderAmount?.paymentSummary?.refundId ?? 'N/A'}
                </OATypography>
              </Box>
            </Box>
            <OrderInvoice tableData={cleartripRefundTableData ?? []} loading={props?.isLoading} />
          </Box>
        </>
      )}
    </Box>
  );
}
