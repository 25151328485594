import { SUPPLIER_NAME } from '../../../../constants';
import { calculateTotalExcludingCtMarkup } from '../PaymentSummary';

export const getCleartripTable = (props: { data: any }, onward: { segments: any[] }) => {
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );

  const orderAmount = props?.data?.orderAmount;
  const currency = orderAmount?.currency;
  const totalAmount = orderAmount?.supplierAmount ?? 0;
  const baseAmount = orderAmount?.flightBasedBreakUp?.[0]?.baseAmount ?? orderAmount?.baseAmount;
  const markup = orderAmount?.flightBasedBreakUp?.[0]?.markup ?? orderAmount?.markup ?? 0;
  const singleTaxAmount = orderAmount?.flightBasedBreakUp?.[0]?.taxesAndFees
    ? `${calculateTotalExcludingCtMarkup(orderAmount?.flightBasedBreakUp?.[0]?.taxesAndFees)}`
    : 0;
  const taxAmount =
    orderAmount?.flightBasedBreakUp?.length === 1 ? singleTaxAmount : orderAmount?.taxAmount ?? 0;

  return [
    {
      id: 0,
      label: `${onwardDeparture?.departure?.airport?.city} -> ${onwardArrival?.arrival?.airport?.city}`,
      value: `${currency} ${parseFloat(baseAmount) + parseFloat(taxAmount)}`,
      header: true
    },
    {
      id: 1,
      label: 'A. Base fare',
      value: `${currency} ${baseAmount}`,
      header: true
    },
    {
      id: 2,
      label: 'B. Taxes & fees',
      value: `${currency} ${taxAmount}`,
      header: true
    },
    {
      id: 3,
      label: 'Markup',
      value: `${currency} ${markup}`,
      header: true
    },
    {
      id: 4,
      label: `Total (amount paid to ${SUPPLIER_NAME[props?.data?.supplierInfo?.supplierCode]})`,
      value: `${currency} ${totalAmount}`
    }
  ];
};

export let getCleartripRefundTable = (props: any) => [
  {
    id: 0,
    label: 'A. Amount paid to Cleartrip',
    value: `INR ${props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.grossAmount ?? 0}`,
    header: true
  },
  {
    id: 1,
    label: 'B. Cancellation charges',
    value: `- INR ${
      props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp
        ? Math.abs(
            parseFloat(
              props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.airlineCharges +
                props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.partnerCharges +
                props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.otherCharges
            )
          )
        : 0
    }`,
    header: true
  },
  {
    id: 2,
    label: 'Airline charges',
    value: `INR ${Math.abs(
      props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.airlineCharges ?? 0
    )}`,
    header: false
  },
  {
    id: 3,
    label: 'Partner charges',
    value: `INR ${Math.abs(
      props?.data?.orderAmount?.refundInfo?.[0]?.refundBreakUp?.partnerCharges ?? 0
    )}`,
    header: false
  },
  {
    id: 4,
    label: 'Total refund amount',
    value: `INR ${props?.data?.orderAmount?.refundInfo?.[0]?.amount ?? 0}`,
    header: false
  }
];

export const roundTripSupplierTableData = (props: { data: any }) => {
  let onward = props?.data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );
  let returnData = props?.data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );
  let returnDeparture = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === returnData?.segments?.length + onward?.segments?.length
  );
  const orderAmount = props?.data?.orderAmount;
  const onwardBreakup = orderAmount?.flightBasedBreakUp?.find(
    (onwardBreakup: any) => onwardBreakup?.flightId === onward?.id
  );

  const returnBreakup = orderAmount?.flightBasedBreakUp?.find(
    (returnBreakup: any) => returnBreakup?.flightId === returnData?.id
  );

  const currency = orderAmount?.currency;
  const onwardTotalAmount = onwardBreakup?.totalAmount ?? 0;
  const onwardBaseAmount = onwardBreakup?.baseAmount ?? 0;

  const onwardMarkup = onwardBreakup?.markup ?? 0;
  const returnTotalAmount = returnBreakup?.totalAmount ?? 0;
  const returnBaseAmount = returnBreakup?.baseAmount ?? 0;
  const returnMarkup = returnBreakup?.markup ?? 0;
  const finalAmount = orderAmount?.supplierAmount ?? 0;
  const baseAmount = orderAmount?.baseAmount ?? 0;
  const taxAmount = orderAmount?.taxAmount ?? 0;
  return orderAmount?.flightBasedBreakUp?.length > 0
    ? [
        {
          id: 0,
          label: `${onwardDeparture?.departure?.airport?.city} -> ${onwardArrival?.arrival?.airport?.city}`,
          value: `${currency} ${parseFloat(onwardTotalAmount) - parseFloat(onwardMarkup)}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${onwardBaseAmount}`,
          header: false
        },
        {
          id: 2,
          label: `${returnArrival?.departure?.airport?.city} -> ${returnDeparture?.arrival?.airport?.city}`,
          value: `${currency} ${parseFloat(returnTotalAmount) - parseFloat(returnMarkup)}`,
          header: true
        },
        {
          id: 3,
          label: 'Base fare',
          value: `${currency} ${returnBaseAmount ?? 0}`,
          header: false
        },
        {
          id: 4,
          label: 'Markup',
          value: `${currency} ${parseFloat(onwardMarkup) + parseFloat(returnMarkup)}`,
          header: true
        },
        {
          id: 5,
          label: `Total (amount paid to ${SUPPLIER_NAME[props?.data?.supplierInfo?.supplierCode]})`,
          value: `${currency} ${finalAmount}`
        }
      ]
    : [
        {
          id: 0,
          label: ` ${onwardDeparture?.departure?.airport?.city} | ${
            returnDeparture?.departure?.airport?.iata ?? ''
          }`,
          value: `${currency} ${finalAmount}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${baseAmount}`,
          header: false
        },
        {
          id: 2,
          label: `Taxes & fees`,
          value: `${currency} ${taxAmount}`,
          header: true,
          roundTrip: true
        },
        {
          id: 3,
          label: `Total (amount paid to ${SUPPLIER_NAME[props?.data?.supplierInfo?.supplierCode]})`,
          value: `${currency} ${finalAmount}`
        }
      ];
};
